import { HTTP_CONTEXT_FIELDS } from './constants';
import getHttpContextValue from './getHttpContextValue';
import isClient from './isClient';

const isSkipFoldRequest = () =>
  isClient()
    ? window.location.search.includes('skipFold=true')
    : getHttpContextValue(HTTP_CONTEXT_FIELDS.IS_SKIP_FOLD_REQ, false);

export default isSkipFoldRequest;
