import isClient from './isClient';

function getWindowHeight() {
  if (isClient()) {
    return (
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    );
  }
  return null;
}

export default getWindowHeight;
