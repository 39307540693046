import React, { useState, useEffect, useRef } from 'react';
import { useIntersection, useWindowScroll } from 'react-use';
import PropTypes from 'prop-types';

import isServer from 'commons/isServer';
import getWindowHeight from 'commons/getWindowHeight';
import isBotRequest from 'commons/isBotRequest';
import isSkipFoldRequest from 'commons/isSkipFoldRequest';

const shouldRenderForNonBot = (componentIndex, disableOnServer) => {
  if (disableOnServer && isServer()) {
    return false;
  }
  return componentIndex < 5;
};

const AboveTheFoldServerRendererLegacy = ({
  children,
  componentIndex = 5,
  disableOnServer,
  renderImmediatelyOnClient,
}) => {
  const [shouldRender, setShouldRender] = useState(() =>
    isBotRequest() || isSkipFoldRequest()
      ? true
      : shouldRenderForNonBot(componentIndex, disableOnServer)
  );
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: `${getWindowHeight() * 0.75 || 0}px`,
    threshold: 0,
  });
  const { y: scrollY } = useWindowScroll();

  useEffect(() => {
    if (shouldRender || !intersection || !intersection.isIntersecting) {
      return;
    }

    setShouldRender(true);
  }, [intersection, scrollY, shouldRender]);

  useEffect(() => {
    if (renderImmediatelyOnClient && !shouldRender) {
      setShouldRender(true);
    }
  }, [renderImmediatelyOnClient, shouldRender]);

  if (shouldRender) {
    return children;
  }

  return (
    <div
      ref={intersectionRef}
      className="AboveTheFoldServerRenderer__filler"
      style={{ minHeight: '500px' }}
    />
  );
};

AboveTheFoldServerRendererLegacy.propTypes = {
  children: PropTypes.node.isRequired,
  componentIndex: PropTypes.number,
  disableOnServer: PropTypes.bool,
  renderImmediatelyOnClient: PropTypes.bool,
};

export default AboveTheFoldServerRendererLegacy;
